import * as React from "react";
import { DatePickerComponent } from "./DatePickerComponent";
import { DatetimePickerTypeEnum } from "ditmer-embla";

interface CheckDateComponentProps {
    label: string;
    value: string;
    settingkey: string;
    dateSelected: (key: string, e: any) => void;
    disabled: boolean;
    readonly: boolean;
}

export const CheckDateComponent = (props: CheckDateComponentProps) => {
    return (<DatePickerComponent
        calenderType={DatetimePickerTypeEnum.DateAndTime}
        dateSelected={props.dateSelected}
        label={props.label}
        settingkey={props.settingkey}
        disabled={props.disabled}
        value={props.value}
        allowClear={true}
        readonly={props.readonly}
    />);
}
