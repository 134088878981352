import * as React from "react";
import {Localization} from "../../modules/localization-module";
import {DatetimePickerModule, DatetimePickerTypeEnum} from "ditmer-embla";
import * as moment from "moment";
import {
    inputDateAndTimeFormat,
    inputDateFormat,
    visibleDateAndTimeFormat,
    visibleDateFormat
} from "../../models/constants/dateFormat";

interface IDatePickerComponentProps {
    label: string;
    value: string;
    settingkey: string;
    calenderType: DatetimePickerTypeEnum;
    disabled: boolean;
    dateSelected: (key: string, e: any) => void;
    allowClear: boolean;
    readonly?: boolean;
}

export class DatePickerComponent extends React.Component<IDatePickerComponentProps> {
    private startValue: string;
    private datepicker: DatetimePickerModule;

    public componentDidMount() {
        this.datepicker = new DatetimePickerModule(`#datepicker-${this.props.settingkey}`, {
            type: this.props.calenderType,
            onSetDate: (event) => this.onChange(this.props.settingkey, event.date),
            allowClear: this.props.allowClear,
            additionalMbscCalenderOptions: {
                clearText: Localization.getText("Global_Clear"),
                steps: {
                    minute:15
                }
            }
        });

        if (this.props.value) {
            const momentValue = moment(new Date(this.props.value), inputDateAndTimeFormat);
            this.startValue = this.props.calenderType === DatetimePickerTypeEnum.Date
                ? momentValue.format(visibleDateFormat)
                : momentValue.format(visibleDateAndTimeFormat);
            this.datepicker.setValue(momentValue.toDate(), true);
        }
    }

    private onChange(key: string, value: string) {
        const datepickerDate = moment(this.datepicker.getValue(), inputDateAndTimeFormat, true);
        let formattedDate = moment(value, inputDateAndTimeFormat, true).format();
        if (!datepickerDate.isValid()) {
            formattedDate = null; // clear is clicked
        }

        this.props.dateSelected(key, formattedDate);
    }

    public render() {
        return <>
            <label htmlFor="datepicker">{this.props.label}</label>
            <div className="input-group">
                <input disabled={this.props.disabled} readOnly={this.props.readonly ?? false} type="text" defaultValue={this.startValue}
                       className="form-control" id={`datepicker-${this.props.settingkey}`}
                       placeholder={Localization.getText("Global_Select")}/>
                <div className="input-group-append">
                    <div className="input-group-text">
                        <svg className="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <use href="/dist/icons/sprite.symbol.svg#calendar"/>
                        </svg>
                    </div>
                </div>
            </div>
        </>;
    }
}
