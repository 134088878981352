export enum GraphicTypeEnum {
    graph = 1,
    graphSingle = 2,
    barChart = 3,
    barometer = 4,
    barometerClean,
    barometerWithPanelheader = 6,
    chartWithHeader = 7,
    weightChart = 8,
    energy = 9,
    energyWithHeader = 10,
    keywords = 11,
    benchMark = 12,
    consistencyBarGraph = 13,
    consistencyBarGraphWithPanelHeader = 14,
    miniatureFocusModel = 15,
    miniatureFocusModelWithHeader = 16,
    focusModel = 17,
    miniaturePressMatrixlWithHeader = 18,
    IQTraits = 19,
    IQTotals = 20,
    circular = 21,

}
